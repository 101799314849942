import {
  americanToDecimal,
  americanToFraction,
  americanToPercent,
} from 'bookmakerjs'

const convertOdds = (price, to) => {
  const converted = {
    american: () => Number(price),
    decimal: () => americanToDecimal(price),
    fractional: () => {
      const fraction = americanToFraction(price).split('/')
      return `${Number(fraction[0])?.toFixed(2)}/${Number(fraction[1])?.toFixed(
        2,
      )}`
    },
    percentage: () => americanToPercent(price) + '%',
  }

  return converted[to] ? converted[to]() : converted.american()
}

export default convertOdds
