import {
  addNoteToMulti,
  changeBetType,
  closeNotesModel,
  toggleAcceptAnyOdds,
  updateMultiBetPrice,
} from '@/redux/slices/bets'
import {
  addNoteToSingle,
  removeBetSlipSecondary,
  setIsParlay,
  toggleBetSlipsMobile,
  toggleBetslips,
  updateBetSlips,
  updateMultipleSlips,
  updateParlayBetPrice,
  updateParlaySlips,
  updateSingleBetPrice,
} from '@/redux/slices/betSlips'
import { formatDate } from '@/utils/dates'
import { Switch } from '@headlessui/react'
import moment from 'moment'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormTextArea from '@/components/form-textarea'
import Button from '@/components/modelButton'
import { getLeagueByName, getTeamByName } from '@/constants/countries-leagues'
import { gameOddSnapshotByIds } from '@/firebase/snapshots'
import { refreshAuthTokenHandler } from '@/firebase/utils'
import useBets from '@/hooks/useBets'
import { LilbitLogoSecond, MultiTicket, SingleTicket } from '@/icons/index'
import authSelector from '@/redux/selectors/auth'
import betsSelector from '@/redux/selectors/bets'
import betSlipsSelector from '@/redux/selectors/betSlips'
import walletSelector from '@/redux/selectors/wallet'
import { openNotesModel } from '@/redux/slices/bets'
import { calculateMultiBetWinPrice } from '@/utils/bets'
import convertOdds from '@/utils/convertOdds'
import CustomImage from '@/utils/customImage'
import { NumberChip } from '@/utils/helper'
import { errorMsg, successMsg } from '@/utils/toast'
import { XIcon } from '@heroicons/react/solid'
import { groupBy } from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMediaQuery } from 'react-responsive'

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

const Model = dynamic(() => import('@/components/model'))
const NotesModel = memo(({ modelData, slips, multiBetNote, intl }) => {
  const dispatch = useDispatch()
  const isOpen = modelData?.id || modelData?.forMulti ? true : false
  const isMulti = modelData?.forMulti ? true : modelData?.id && false
  const seletedSlip = useMemo(
    () => slips?.find((slip) => slip.id === modelData?.id),
    [isOpen],
  )
  const [tempNote, setTempNote] = useState(
    (isMulti ? multiBetNote : seletedSlip?.note) || '',
  )

  const handleClose = () => {
    dispatch(closeNotesModel())
    setTempNote('')
  }

  const handleSubmit = () => {
    if (!tempNote) {
      return errorMsg(
        intl.formatMessage({
          id: 'error.addBetNotes',
          defaultMessage: 'Kindly Add Bet Notes First',
        }),
      )
    }
    if (isMulti) {
      dispatch(addNoteToMulti(tempNote))
      successMsg(
        intl.formatMessage({
          id: 'success.betNotesAdded',
          defaultMessage: 'Bet Notes Added Successfully',
        }),
      )
      handleClose()
    } else {
      dispatch(
        addNoteToSingle({
          note: tempNote,
          id: modelData?.id,
        }),
      )
      successMsg(
        intl.formatMessage({
          id: 'success.betNotesAdded',
          defaultMessage: 'Bet Notes Added Successfully',
        }),
      )
      handleClose()
    }
  }

  useEffect(() => {
    if (isMulti) {
      setTempNote(multiBetNote || '')
    } else {
      const prevNote = seletedSlip?.note || ''
      setTempNote(prevNote || '')
    }
  }, [isOpen])

  return (
    <Model
      isOpen={isOpen}
      onClose={handleClose}
      showCloseIcon={true}
      className="max-w-md overflow-auto max-h-screen"
    >
      <div className="font-inter space-y-2 ">
        <h2 className="font-bold text-xl">Bet Notes</h2>
        <p className="text-base font-normal">
          This will make it easier for you to keep track of your betting details
          and can serve as a record for future bets.
        </p>
      </div>
      <div className="mt-12 flex flex-col space-y-6">
        <FormTextArea
          value={tempNote}
          onChange={(e) => setTempNote(e.target.value)}
          rows={6}
          label="Insert notes"
          placeholder="Type your bet notes."
          className="flex flex-col items-start"
        />
        <Button label="Save" active onClick={handleSubmit} />
      </div>
    </Model>
  )
})
NotesModel.displayName = 'NotesModel'

const ChangeOddsModel = memo(({ isOpen, onClickYes, user, handleClose }) => {
  return (
    <Model
      isOpen={isOpen}
      onClose={handleClose}
      showCloseIcon={true}
      className="max-w-md overflow-auto max-h-screen"
    >
      <div className="font-inter space-y-2">
        <p className="text-base font-normal">
          Odds are changed. Do you still want to place bet ?
        </p>
      </div>
      <div className="mt-12 flex flex-col space-y-6">
        <Button label="Yes" active onClick={() => onClickYes(user)} />
        <Button isSecondary label="No" onClick={handleClose} />
      </div>
    </Model>
  )
})
ChangeOddsModel.displayName = 'ChangeOddsModel'

const TabButton = ({
  label,
  icon: Icon,
  betType,
  className,
  changeBtn,
  font,
  ...rest
}) => (
  <button
    onClick={() => changeBtn(label)}
    className={`${
      className
        ? ''
        : `flex items-center justify-center flex-1 space-x-2 text-white px-[1.375rem] py-2 rounded-lg cursor-pointer ${
            betType ? `bg-[#008000]` : 'hover:bg-[#fff]/10'
          }`
    }`}
    {...rest}
  >
    {Icon && <Icon alt="icon" />}
    <span
      className={`${
        className
          ? 'font-inter font-medium text-xs'
          : 'text-sm font-medium font-inter whitespace-nowrap'
      }`}
    >
      {label}
    </span>
  </button>
)

const SelectBetPrice = memo(
  ({
    betNotes,
    betPrice,
    onChange,
    onNoteClick,
    pathname,
    pastBet,
    price,
    betWon,
    possibleWinPrice,
    betDate,
    isLive,
    isProp = false,
    betType,
    globalView,
  }) => {
    const [selectionActive, setSelectionActive] = React.useState(false)
    // const { pastBet } = useSelector((state) => state.bets)
    const [tempValue, setValue] = useState(+betPrice === 0 ? '' : +betPrice)
    const options = [5, 25, 100]
    const limit = betType === 'multi' ? 10000 : isProp || isLive ? 200 : 5000

    const calculatePossibleWin =
      !possibleWinPrice &&
      (Number(price) > 0
        ? Number(betPrice) * (Number(price) / 100)
        : Number(betPrice) * (100 / Math.abs(Number(price))))

    useEffect(() => {
      const timeout = setTimeout(() => {
        const stringToNumber = +Math.abs(Number(tempValue))
        onChange && onChange(stringToNumber)
      }, 300)

      return () => {
        clearTimeout(timeout)
      }
    }, [tempValue])

    const onInputChange = (e) => {
      const val = e.target.value
      if (/^[0-9]+\.?[0-9]*$/.test(val)) {
        if (+val <= limit) {
          setValue(val)
        } else {
          setValue(Math.abs(limit))
        }
      } else {
        setValue('')
      }
    }

    return (
      <div className="space-y-[6px] ">
        {!pastBet ? (
          <>
            {globalView && (
              <>
                <div className="flex gap-2">
                  <div
                    className={`flex w-full flex-row items-start mouse-pointer rounded-lg py-[6px] px-[6px] font-normal font-inter text-base border ${
                      selectionActive ? 'border-[#008000]' : 'border-white/5'
                    } focus:ring-[#008000] focus:ring-2 focus:outline-none`}
                    onClick={() => setSelectionActive(!selectionActive)}
                  >
                    <div className="my-auto w-full flex flex-col">
                      <span className="whitespace-nowrap text-xs text-gray-400">
                        Bet Amount
                      </span>
                      <div className="flex justify-center items-center">
                        <p className="text-[#F7941D] mr-1">$</p>
                        <input
                          type="text"
                          onWheel={(e) => e.target.blur()}
                          value={tempValue}
                          onChange={onInputChange}
                          className="bg-transparent border-transparent w-full p-0"
                          // autoFocus
                        />
                      </div>
                    </div>
                  </div>

                  {betType === 'single' && (
                    <div
                      className={`flex flex-row items-start mouse-pointer rounded-lg py-[6px] px-[6px] font-normal font-inter text-base border ${
                        selectionActive ? 'border-[#008000]' : 'border-white/5'
                      } focus:ring-[#008000] focus:ring-2 focus:outline-none`}
                    >
                      <div className="my-auto w-full flex flex-col">
                        <span className="whitespace-nowrap text-xs text-gray-400">
                          To Win
                        </span>
                        <div className="flex justify-center items-center">
                          <p className="text-[#F7941D] mr-1">$</p>
                          <input
                            type="text"
                            disabled
                            onWheel={(e) => e.target.blur()}
                            value={
                              calculatePossibleWin === 0
                                ? ''
                                : calculatePossibleWin
                            }
                            className="bg-transparent border-transparent w-full p-0"
                            // autoFocus
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex items-center justify-between">
                  {selectionActive &&
                    options.map((option, index) => (
                      <div
                        key={option + index}
                        className={`w-16 h-10 flex items-center justify-center rounded-lg focus:ring-[#008000] focus:ring-2 focus:outline-none hover:bg-[#008000] mouse-pointer`}
                        onClick={() =>
                          setValue((prev) => {
                            return +prev + +option <= limit
                              ? +prev + +option
                              : limit
                          })
                        }
                      >
                        ${option}
                      </div>
                    ))}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="flex gap-4">
              <span className="text-xs font-normal font-inter">Date:</span>
              <span className="text-xs font-normal font-inter">{betDate}</span>
            </div>
            <div className="flex gap-4">
              <span className="text-xs font-normal font-inter">
                Bet amount:
              </span>
              <span className="text-xs font-normal font-inter">
                {betPrice || price}
              </span>
            </div>
            <div className="flex gap-4">
              <span className="text-xs font-normal font-inter">
                Est. Payout:
              </span>
              <span className="text-xs font-normal font-inter capitalize">
                {possibleWinPrice
                  ? Number(possibleWinPrice).toFixed(8)
                  : calculatePossibleWin.toFixed(8)}
              </span>
            </div>
            <div className="flex gap-4">
              {betWon === false || betWon === true ? (
                <div
                  className={`bg-[#12B76A]/10 ${
                    betWon ? 'text-[#12B76A]' : 'text-[#F04438]'
                  } capitalize rounded-2xl px-2 py-0.5 font-inter font-medium text-sm whitespace-nowrap w-fit`}
                >
                  {betWon ? 'Win' : 'Lost'}
                </div>
              ) : (
                <span className="inline-flex items-center gap-x-1.5 text-red-100 rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset ring-red-400">
                  <svg
                    className="h-1.5 w-1.5 fill-red-500 animate-pulse"
                    viewBox="0 0 6 6"
                    aria-hidden="true"
                  >
                    <circle cx={3} cy={3} r={3} />
                  </svg>
                  Active
                </span>
              )}
            </div>
          </>
        )}
      </div>
    )
  },
)
SelectBetPrice.displayName = 'SelectBetPrice'

const RenderSingleSlip = memo(
  ({ item, matchDescription, league, pathname, isSettled, pastBet }) => {
    const dispatch = useDispatch()
    const { oddsDisplayType } = useSelector(betSlipsSelector)
    const teamInformation = getTeamByName({
      teamName: item?.teamName || item?.selection || '',
      league: league.name,
      sport: item?.match?.sport,
    })
    return (
      <SlipLayout
        label={matchDescription}
        settled={isSettled}
        iconUrl={league?.logo}
        pastBet={pastBet}
        onClose={() => {
          dispatch(removeBetSlipSecondary(item?.id))
        }}
      >
        <div className="space-y-3">
          <div className="flex flex-row items-center gap-3">
            <div className="relative">
              <div className="w-10 h-10 overflow-hidden rounded-md flex justify-center items-center">
                {item?.selection && item?.selection !== 'Draw' ? (
                  typeof teamInformation?.logo === 'function' ? (
                    <div className="relative h-9 w-9 rounded-full bg-white overflow-hidden flex justify-center items-center">
                      <teamInformation.logo />
                    </div>
                  ) : (
                    <CustomImage src={teamInformation?.logo} cls={'w-9 h-9'} />
                  )
                ) : (
                  <league.logo />
                )}
              </div>
            </div>
            <div className="flex-1">
              <div className="flex flex-row items-center space-x-2">
                <span className="text-sm font-semibold font-inter text-[#A6EF67]">
                  {item?.market_name}
                </span>
              </div>
              <div className="flex flex-row items-center space-x-2">
                <span className="text-sm font-semibold font-inter text-[#A6EF67]">
                  {!item?.teamName
                    ? item?.name
                    : item?.teamName && item?.teamName !== 'draw'
                    ? 'Winner'
                    : 'Draw'}
                </span>
                <div>
                  <NumberChip
                    number={convertOdds(item?.price, oddsDisplayType)}
                    className="bg-white/5 text-[#F7941D]"
                  />
                </div>
              </div>
              <div className="flex flex-row items-center space-x-1 text-sm font-medium font-inter text-white">
                {!item?.teamName ? null : item?.teamName &&
                  item?.teamName !== 'draw' ? (
                  <div>
                    <span>
                      {item?.bet_points
                        ? item?.teamName + ' ' + '(' + item?.bet_points + ')'
                        : item?.teamName}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <SelectBetPrice
            betPrice={item?.betPrice || 0}
            onChange={
              !pastBet
                ? (newPrice) => {
                    dispatch(
                      updateSingleBetPrice({
                        betPrice: newPrice,
                        id: item?.id,
                      }),
                    )
                  }
                : ''
            }
            betNotes={item?.note}
            betWon={item?.won}
            possibleWinPrice={item?.possibleWinPrice}
            pathname={pathname}
            onNoteClick={() => dispatch(openNotesModel(item?.id))}
            pastBet={pastBet}
            price={item?.price}
            betDate={formatDate(
              moment.utc(item?.placedAt).local(),
              'MMM DD, YYYY',
            )}
            isProp={item?.isProp}
            isLive={item?.is_live}
            betType={'single'}
            globalView={true}
          />
        </div>
      </SlipLayout>
    )
  },
)
RenderSingleSlip.displayName = 'RenderSingleSlip'

const RenderMultiSlips = memo(
  ({
    item,
    isSameLeague,
    matchDescription,
    league,
    isSettled,
    pastBet,
    pathname,
    multiItem,
    isParlay,
  }) => {
    const { oddsDisplayType } = useSelector(betSlipsSelector)
    const dispatch = useDispatch()
    const teamInformation = getTeamByName({
      teamName: item?.teamName,
      league: league.name,
      sport: item?.match?.sport,
    })
    return (
      <SlipLayout
        isSameLeague={isSameLeague}
        settled={isSettled}
        label={matchDescription}
        pastBet={pastBet}
        iconUrl={league?.logo}
        isParlay={isParlay}
        onClose={() => {
          dispatch(removeBetSlipSecondary(item?.id))
        }}
      >
        <div className="space-y-3">
          <div className="flex flex-row items-center gap-3">
            <div className="relative">
              {item?.teamName && item?.teamName !== 'draw' ? (
                typeof teamInformation?.logo === 'function' ? (
                  <div className="relative h-8 w-8 rounded-full bg-white overflow-hidden flex justify-center items-center">
                    <teamInformation.logo />
                  </div>
                ) : (
                  <CustomImage src={teamInformation?.logo} cls={'w-10 h-10'} />
                )
              ) : (
                <div className="relative h-8 w-8 rounded-full bg-white overflow-hidden flex justify-center items-center">
                  <league.logo />
                </div>
              )}
            </div>
            <div className="flex-1">
              <div className="flex flex-row items-center space-x-2">
                <span className="text-sm font-semibold font-inter text-[#A6EF67]">
                  {item?.market_name}
                </span>
              </div>
              <div className="flex flex-row items-center space-x-2">
                <span className="text-sm font-semibold font-inter text-[#A6EF67]">
                  {!item?.teamName
                    ? item?.name
                    : item?.teamName && item?.teamName !== 'draw'
                    ? 'Winner'
                    : 'Draw'}
                </span>
                <div>
                  {!isParlay && (
                    <NumberChip
                      number={convertOdds(item?.price, oddsDisplayType)}
                      className="bg-white/5 text-[#F7941D]"
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-row items-center space-x-1 text-sm font-medium font-inter text-white">
                {!item?.teamName ? null : item?.teamName &&
                  item?.teamName !== 'draw' ? (
                  <div>
                    <span>
                      {item?.bet_points
                        ? item?.teamName + ' ' + '(' + item?.bet_points + ')'
                        : item?.teamName}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <SelectBetPrice
            betPrice={item?.betPrice || 0}
            onChange={
              !pastBet
                ? (newPrice) => {
                    dispatch(
                      updateSingleBetPrice({
                        betPrice: newPrice,
                        id: item?.id,
                      }),
                    )
                  }
                : ''
            }
            betNotes={multiItem ? multiItem?.note : item?.note}
            betWon={multiItem ? multiItem?.won : item?.won}
            possibleWinPrice={
              multiItem ? multiItem?.possibleWinPrice : item?.possibleWinPrice
            }
            pathname={pathname}
            onNoteClick={() => dispatch(openNotesModel(item?.id))}
            pastBet={pastBet}
            price={multiItem ? multiItem?.price : item?.price}
            betDate={formatDate(
              moment
                .utc(multiItem ? multiItem?.placedAt : item?.placedAt)
                .local(),
              'MMM DD, YYYY',
            )}
            isProp={item?.isProp}
            isLive={item?.is_live}
            betType={'multi'}
            globalView={false}
          />
        </div>
      </SlipLayout>
    )
  },
)
RenderMultiSlips.displayName = 'RenderMultiSlips'

const SlipLayout = memo(
  ({
    iconUrl,
    label,
    pastBet,
    children,
    onClose,
    settled,
    isSameLeague,
    isParlay,
    ...rest
  }) => {
    return (
      <div
        className={`relative space-y-4 rounded-lg pb-4 overflow-hidden bg-white/5 `}
        {...rest}
      >
        <div
          className={`flex items-center justify-between py-3 pr-2 pl-6 ${
            !isParlay && (isSameLeague || settled)
              ? 'bg-[#4D000D]'
              : 'bg-white/5'
          }`}
        >
          <div className="flex items-center space-x-1 flex-1">
            {iconUrl && (
              <div className="w-6 h-6 overflow-hidden rounded-md bg-white flex justify-center items-center">
                {iconUrl()}
              </div>
            )}
            <span className="text-xs font-medium font-inter truncate w-48">
              {label}
            </span>
          </div>
          {!pastBet && (
            <div
              onClick={onClose && onClose}
              className="cursor-pointer close-icon-right"
            >
              <XIcon className="w-4 h-4 text-gray-300" />
            </div>
          )}
        </div>
        {settled ? (
          <div className="absolute right-2 top-[2.2rem] text-[#4D000D] font-extrabold text-sm opacity-70">
            Settled
          </div>
        ) : null}
        <div className="px-6 pt-2">{children}</div>
      </div>
    )
  },
)
SlipLayout.displayName = 'SlipLayout'

const NoSlips = () => {
  return (
    <div className="2xl:py-24 py-0">
      <div className="flex flex-col justify-center items-center  ">
        <div className="">
          <LilbitLogoSecond className="w-24 h-24" />
        </div>
        <h6 className=" mt-2.5 text-sm text-white/50">
          <FormattedMessage
            id="rightSidebar.emptyMessageImageDescription"
            defaultMessage="Bet Slip is Empty"
          />
        </h6>
        <h3 className="mt-2.5 text-base font-bold">
          <FormattedMessage
            id="rightSidebar.emptyMessagePrompt"
            defaultMessage="Bet a Lil Bit Now!"
          />
        </h3>
      </div>
    </div>
  )
}

function RightSidebar() {
  const router = useRouter()
  const intl = useIntl()
  const dispatch = useDispatch()
  const { user, isAuthenticated } = useSelector(authSelector)
  const {
    slips: BetsData,
    parlaySlips,
    betPlaceLoad,
    placedBets,
    parlayBetPrice,
    isLoading,
    isParlay,
    authError,
  } = useSelector(betSlipsSelector)
  const { internalWallet } = useSelector(walletSelector)
  const {
    betType,
    acceptAnyOdds,
    price: multiBetPrice,
    multibetNote,
    notesModel,
  } = useSelector(betsSelector)
  const { placeBetHandler, clearBetsHandler } = useBets()
  const [placeLoading, setPlaceBetLoader] = useState(false)
  const [oddChange, setOddChange] = useState(false)
  const [oddsModalVisible, setOddsModalVisible] = useState(false)
  const [activeColor, setActiveColor] = useState(true)
  const [pastColor, setPastColor] = useState(false)
  const [pastBet, setPastBet] = useState(false)
  const [singleFilterBets, setSingleFilterBets] = useState([])
  const [multiFilterBets, setMultiFilterBets] = useState([])
  const sm = useMediaQuery({ query: '(max-width: 639px)' })
  const componentRef = useRef(null)
  const listingData = [...BetsData].reverse()
  const updateSlips = (payload) => {
    dispatch(updateMultipleSlips(payload))
  }

  useEffect(() => {
    if (listingData.length > 0) {
      const groupedData = groupBy(listingData, (item) => [item.match.id])
      Object.keys(groupedData).map((gameId) => {
        const oddIds = groupedData[gameId].map((group) => group.id)
        if (oddIds.length) {
          gameOddSnapshotByIds(gameId, oddIds, updateSlips)
        }
      })
    }
  }, [listingData])

  const oddData = listingData.map((data) => {
    return data.bet_points ? data.bet_points : data.price
  })

  const previousValue = useRef(null)

  let totalBetPrice =
    betType?.toLowerCase() === 'single'
      ? listingData?.reduce((acc, curr) => acc + Number(curr?.betPrice), 0)
      : multiBetPrice

  const possibleWinPrice =
    betType?.toLowerCase() === 'single'
      ? listingData?.reduce((acc, curr) => {
          const { betPrice, price } = curr
          if (Number(price) > 0) {
            return acc + Number(betPrice) * (Number(price) / 100)
          } else {
            return acc + Number(betPrice) * (100 / Math.abs(Number(price)))
          }
        }, 0)
      : // Calculate possible win price for multi bet with MultiBetPrice
      isParlay
      ? Number(parlayBetPrice) > 0
        ? Number(multiBetPrice) * (Number(parlayBetPrice) / 100)
        : Number(multiBetPrice) * (100 / Math.abs(Number(parlayBetPrice)))
      : calculateMultiBetWinPrice(listingData, multiBetPrice)

  const onClickActions = (label) => {
    dispatch(changeBetType(label))
  }

  const arrayEquals = (a, b) => {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    )
  }

  const placeBetHandle = (userInfo) => {
    if (!userInfo?.uid) {
      return router.push('/login')
    }
    if (oddChange === true) {
      setOddsModalVisible(true)
      return
    }
    betHandler(userInfo)
  }

  const tryBetAgain = async () => {
    const tokenAccess = await refreshAuthTokenHandler(user)
    placeBetHandle({ ...user, accessToken: tokenAccess })
  }

  useEffect(() => {
    if (authError) {
      tryBetAgain()
    }
  }, [authError])

  const closeOddsModal = () => {
    setOddsModalVisible(false)
  }

  const checkContainsSettledBets = useMemo(
    () => listingData?.some((slip) => slip?.isSettled || false),
    [listingData],
  )

  const betHandler = (userInfo) => {
    if (!isAuthenticated) {
      router.push('/login')
      return null
    }

    if (!listingData?.length || listingData?.length === 0) {
      return errorMsg(
        intl.formatMessage({
          id: 'error.noBetsSelected',
          defaultMessage: 'No Bets Selected',
        }),
      )
    }

    if (checkContainsSettledBets) {
      return errorMsg(
        intl.formatMessage({
          id: 'error.containsSettledBets',
          defaultMessage: 'Contains Settled Bet(s)',
        }),
      )
    }

    if (Number(totalBetPrice) <= 0) {
      return errorMsg(
        intl.formatMessage({
          id: 'error.betAmountPositive',
          defaultMessage: 'Every bet amount should be greater than 0',
        }),
      )
    }

    if (betType === 'single') {
      placeSingleBet(userInfo)
    } else if (betType === 'multi') {
      if (listingData?.length === 1) {
        placeMultiIntoSingle(userInfo)
      } else {
        placeMultiBet(userInfo)
      }
    }
  }

  const placeMultiIntoSingle = (userInfo) => {
    setPlaceBetLoader(true)
    placeBetHandler({
      betSlips: [
        {
          ...listingData[0],
          betPrice: totalBetPrice,
        },
      ],
      betType: 'single',
      price: totalBetPrice,
      user: userInfo,
      activeWallet: user.activeWallet,
      onComplete: () => {
        setPlaceBetLoader(false)
      },
    })
  }

  const placeSingleBet = (userInfo) => {
    const isPriceValid = listingData?.every(
      (list) => Number(list?.betPrice) > 0,
    )

    if (!isPriceValid) {
      return errorMsg(
        intl.formatMessage({
          id: 'error.betAmountPositive',
          defaultMessage: 'Every bet amount should be greater than 0',
        }),
      )
    }

    setPlaceBetLoader(true)
    placeBetHandler({
      betSlips: listingData,
      betType: 'single',
      price: totalBetPrice,
      user: userInfo,
      activeWallet: user.activeWallet,
      onComplete: () => {
        setPlaceBetLoader(false)
      },
    })
  }

  const placeMultiBet = (userInfo) => {
    const containsDublicateLeague = listingData
      .map((item) => item?.match?.id)
      .some((item, index, arr) => arr.indexOf(item) !== index)

    const containsDublicateFuture = listingData
      .reduce((acc, curr) => {
        if (curr?.future) {
          return [...acc, curr?.name]
        } else {
          return [...acc]
        }
      }, [])
      .some((item, index, arr) => arr.indexOf(item) !== index)

    if (containsDublicateLeague || containsDublicateFuture) {
      return errorMsg(
        intl.formatMessage({
          id: 'error.duplicateLeagueBet',
          defaultMessage:
            'You cannot place a bet on two odds in the same match.',
        }),
      )
    }

    setPlaceBetLoader(true)
    placeBetHandler({
      betSlips: listingData,
      betType: 'multi',
      note: multibetNote,
      price: totalBetPrice,
      possibleWinPrice: possibleWinPrice,
      activeWallet: user.activeWallet,
      user: userInfo,
      onComplete: () => {
        setPlaceBetLoader(false)
      },
    })
  }

  const pastBetsHandler = () => {
    setActiveColor(false)
    setPastColor(true)
    setPastBet(true)
  }

  const activeBetsHandler = () => {
    setPastColor(false)
    setActiveColor(true)
    setPastBet(false)
  }

  useEffect(() => {
    previousValue.current = oddData
  }, [oddData])

  useEffect(() => {
    if (
      !acceptAnyOdds &&
      oddData.length > 0 &&
      previousValue.current.length > 0
    ) {
      const isEqual = arrayEquals(oddData, previousValue.current)
      if (!isEqual) {
        setOddChange(true)
      }
    } else if (acceptAnyOdds === true) {
      setOddChange(false)
    }
  }, [oddData, acceptAnyOdds])

  useEffect(() => {
    if (placedBets.length > 0) {
      const filteredBets = placedBets.reduce(
        (acc, curr) => {
          if (curr.betType === 'multi') {
            acc.multi.push(curr)
          } else {
            acc.single.push(curr)
          }

          return acc
        },
        {
          single: [],
          multi: [],
        },
      )
      setSingleFilterBets(filteredBets.single)
      setMultiFilterBets(filteredBets.multi)
    }
  }, [placedBets])

  const updateParlayBets = async () => {
    const parlayData = BetsData.reduce(
      (curr, acc) => [
        ...curr,
        {
          market: acc.market_name,
          name: acc.name,
          game_id: acc.id.split(':')[0],
        },
      ],
      [],
    )

    const parlayRes = await fetch('/api/parlay', {
      method: 'POST',
      body: JSON.stringify(parlayData),
    })
      .then((res) => res.json())
      .catch((err) => reject(err.message))
    if (parlayRes.data) {
      if (parlayRes.data.error) {
        if (parlayRes.data?.missing_entries?.length) {
          const newListingData = BetsData.filter(
            (game) =>
              !parlayRes.data.missing_entries.find(
                (missingData) =>
                  game.id.split(':')[0] === missingData.game_id &&
                  game.name === missingData.name,
              ),
          )
          dispatch(updateBetSlips(newListingData))
          errorMsg('Parlay bets that could not be made were deleted')
        } else {
          errorMsg(parlayRes.data.error)
        }
      } else {
        const { price } = parlayRes.data
        dispatch(updateParlaySlips([...BetsData].reverse()))
        dispatch(updateParlayBetPrice(price))
        dispatch(setIsParlay(true))
      }
    } else {
      errorMsg(parlayRes.message || parlayRes.error)
    }
  }

  const parlayHandle = async (val) => {
    if (val === 'Straight') {
      dispatch(updateParlaySlips([]))
      dispatch(updateParlayBetPrice(0))
      dispatch(setIsParlay(false))
    } else {
      updateParlayBets()
    }
  }

  useEffect(() => {
    if (isParlay) {
      updateParlayBets()
    }
  }, [BetsData])
  const renderSlips = useMemo(
    () => (
      <div
        className={`p-4 space-y-2 scroller h-[100vh]  ${
          listingData.length === 0 && !pastBet
            ? 'flex justify-center items-center'
            : ''
        }`}
        ref={componentRef}
      >
        {!pastBet ? (
          <>
            {listingData?.length === 0 ? (
              <NoSlips />
            ) : (
              <>
                {betType?.toLowerCase() === 'single' ? (
                  <>
                    {listingData?.map((item, index) => {
                      const league = getLeagueByName(item?.match?.league)
                      let matchDescription
                      if (item?.future) {
                        matchDescription = item.future
                      } else {
                        const homeTeam = getTeamByName({
                          teamName: item?.match?.home_team,
                          league: item?.match?.league,
                        })
                        const awayTeam = getTeamByName({
                          teamName: item?.match?.away_team,
                          league: item?.match?.league,
                        })
                        matchDescription = `${homeTeam?.name} vs ${awayTeam?.name}`
                      }
                      return (
                        <RenderSingleSlip
                          key={index + item?.id}
                          isSettled={item?.isSettled || false}
                          item={item}
                          matchDescription={matchDescription}
                          league={league}
                          pathname={router.pathname}
                          pastBet={pastBet}
                        />
                      )
                    })}
                  </>
                ) : (
                  <div className="flex flex-col space-y-2">
                    <div className="p-1">
                      <div className="border-b border-gray-700">
                        <nav className="flex space-x-10" aria-label="Tabs">
                          {/* {['Straight', 'Parlay'].map((tab) => (
                            <button
                              key={tab}
                              className={classNames(
                                (isParlay && tab === 'Parlay') ||
                                  (!isParlay && tab !== 'Parlay')
                                  ? 'border-white text-white font-bold'
                                  : 'border-transparent text-gray-400 hover:border-gray-300 hover:text-gray-700 font-medium',
                                'border-b-2 py-4 px-1 text-center text-sm uppercase',
                              )}
                              onClick={() => parlayHandle(tab)}
                            >
                              {tab}
                            </button>
                          ))} */}
                          <div
                            className={
                              'border-white text-white font-bold border-b-2 py-4 px-1 text-center text-sm uppercase'
                            }
                          >
                            Straight
                          </div>
                        </nav>
                      </div>
                    </div>
                    {(isParlay ? parlaySlips : listingData)?.map(
                      (item, index) => {
                        const league = getLeagueByName(item?.match?.league)
                        let matchDescription
                        if (item?.future) {
                          matchDescription = item.future
                        } else {
                          const homeTeam = getTeamByName({
                            teamName: item?.match?.home_team,
                            league: item?.match?.league,
                          })
                          const awayTeam = getTeamByName({
                            teamName: item?.match?.away_team,
                            league: item?.match?.league,
                          })
                          matchDescription = `${homeTeam?.name} vs ${awayTeam?.name}`
                        }

                        const isSameLeague =
                          listingData?.reduce(
                            (acc, curr) =>
                              (acc +=
                                curr?.match?.id === item?.match?.id ||
                                (curr?.future &&
                                  item?.future &&
                                  curr?.name === item?.name)
                                  ? 1
                                  : 0),
                            0,
                          ) > 1

                        return (
                          <RenderMultiSlips
                            key={item?.id + index}
                            item={item}
                            isSettled={item?.isSettled || false}
                            isSameLeague={isSameLeague}
                            matchDescription={matchDescription}
                            league={league}
                            pastBet={pastBet}
                            isParlay={isParlay}
                          />
                        )
                      },
                    )}
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {betType?.toLowerCase() === 'single' ? (
              <>
                {singleFilterBets.length > 0 &&
                  singleFilterBets?.map((item, index) => {
                    const league = getLeagueByName(item?.match?.league)
                    let matchDescription
                    if (item?.future) {
                      matchDescription = item.future
                    } else {
                      const homeTeam = getTeamByName({
                        teamName: item?.match?.home_team,
                        league: item?.match?.league,
                      })
                      const awayTeam = getTeamByName({
                        teamName: item?.match?.away_team,
                        league: item?.match?.league,
                      })
                      matchDescription = `${homeTeam?.name} vs ${awayTeam?.name}`
                    }
                    return (
                      <RenderSingleSlip
                        key={index + item?.id}
                        isSettled={item?.isSettled || false}
                        item={item}
                        matchDescription={matchDescription}
                        league={league}
                        pathname={router.pathname}
                        pastBet={pastBet}
                      />
                    )
                  })}
                {isLoading && <p>Loading ....</p>}
              </>
            ) : (
              <>
                {multiFilterBets.length > 0 &&
                  multiFilterBets?.map((multi) => {
                    return multi.betSlips.map((item, index) => {
                      const league = getLeagueByName(item?.match?.league)
                      let matchDescription
                      if (item?.future) {
                        matchDescription = item.future
                      } else {
                        const homeTeam = getTeamByName({
                          teamName: item?.match?.home_team,
                          league: item?.match?.league,
                        })
                        const awayTeam = getTeamByName({
                          teamName: item?.match?.away_team,
                          league: item?.match?.league,
                        })
                        matchDescription = `${homeTeam?.name} vs ${awayTeam?.name}`
                      }

                      const isSameLeague =
                        placedBets?.betSlips?.reduce(
                          (acc, curr) =>
                            (acc +=
                              curr?.match?.id === item?.match?.id ? 1 : 0),
                          0,
                        ) > 1

                      return (
                        <RenderMultiSlips
                          key={item?.id + index}
                          multiItem={multi}
                          item={item}
                          isSettled={item?.isSettled || false}
                          isSameLeague={isSameLeague}
                          matchDescription={matchDescription}
                          league={league}
                          pathname={router.pathname}
                          pastBet={pastBet}
                        />
                      )
                    })
                  })}
                {isLoading && <p>Loading ....</p>}
              </>
            )}
          </>
        )}
      </div>
    ),
    [listingData, betType, pastBet, placedBets, user],
  )

  const onClickClose = () => {
    if (sm) {
      dispatch(toggleBetSlipsMobile(false))
      return
    }
    dispatch(toggleBetslips())
  }
  return (
    <>
      <div className="w-[19rem] hidden lg:block  " />
      <div
        className={`${
          sm ? 'mx-0' : 'mx-8'
        } lg:mx-0 lg:w-[19rem] lg:absolute lg:right-0 rounded-lg lg:rounded-tl-lg  overflow-auto  ${
          sm
            ? 'h-[calc(100dvh-70px)]'
            : 'max-h-[calc(100vh-6rem)] lg:min-h-[calc(100vh-6rem)] min-h-[54rem]'
        }
         flex flex-col ${
           router.pathname === '/home' ? 'bg-[#0E1116]' : 'bg-white/5'
         } `}
      >
        <div className="relative px-2 pt-5 pb-4 bg-white/5 space-y-2 ">
          <div
            className="block sm:hidden absolute top-3 right-3 p-1 rounded-full hover:bg-white/5 duration-300 cursor-pointer"
            onClick={onClickClose}
          >
            <XIcon className="w-5 h-5 text-gray-300" />
          </div>
          <div className="flex flex-row items-center justify-center gap-x-4 sm:justify-between sm:gap-1 px-2">
            <div
              className={` ${
                activeColor ? 'bg-[#008000]' : ''
              } flex flex-row items-center gap-2 px-2 rounded-[40px]`}
            >
              <TabButton
                label="BET SLIP"
                className
                onClick={activeBetsHandler}
              />
              <NumberChip
                number={listingData?.length}
                className="bg-[#008000]"
              />
            </div>
            {user && user.uid && (
              <div
                className={` ${
                  pastColor ? 'bg-[#008000]' : ''
                } flex flex-row items-center gap-1 cursor-pointer px-2 rounded-[40px]`}
              >
                <TabButton
                  label="Placed Bets"
                  className
                  onClick={pastBetsHandler}
                />
                <NumberChip
                  number={placedBets.length}
                  className="bg-[#008000]"
                />
              </div>
            )}
          </div>
          <div className="flex-1 flex flex-row gap-2 flex-wrap items-center justify-between p-2 bg-white/10 rounded-xl relative">
            <TabButton
              // disabled={listingData?.length > 1}
              betType={betType?.toLowerCase() === 'single'}
              label="Single"
              icon={(props) => <SingleTicket className="w-5 h-5" {...props} />}
              onClick={() => {
                onClickActions('single')
              }}
            />
            <TabButton
              // disabled={listingData?.length === 1}
              betType={betType?.toLowerCase() === 'multi'}
              label="Multi"
              icon={(props) => <MultiTicket className="w-5 h-5" {...props} />}
              onClick={() => {
                onClickActions('multi')
              }}
            />
          </div>
        </div>
        {renderSlips}
        {!pastBet && (
          <>
            <div className="border-b border-white/20 mt-auto flex justify-end items-center">
              {isParlay && (
                <div className="p-4 text-sm text-green-400">
                  +{parlayBetPrice}
                </div>
              )}
              <p
                className="font-normal text-xs p-4 cursor-pointer hover:text-[#F7941D]"
                onClick={clearBetsHandler}
              >
                <FormattedMessage
                  id="rightSidebar.footerClearAll"
                  defaultMessage="Clear all"
                />
              </p>
            </div>
            <div className="p-4 sm:pb-6 space-y-3">
              {betType?.toLowerCase() === 'single' ? null : (
                <>
                  {listingData?.length > 0 && (
                    <SelectBetPrice
                      betPrice={multiBetPrice}
                      onChange={(newPrice) => {
                        dispatch(updateMultiBetPrice(newPrice))
                      }}
                      betType={betType}
                      pathname={router.pathname}
                      onNoteClick={() => dispatch(openNotesModel(false))}
                      globalView={true}
                    />
                  )}
                </>
              )}
              <div className="flex flex-row justify-between flex-wrap gap-3">
                <p className="font-normal text-xs font-inter">
                  <FormattedMessage
                    id="rightSidebar.footerAcceptOddsChanges"
                    defaultMessage=" Accept any odds changes"
                  />
                </p>
                <Switch
                  checked={acceptAnyOdds}
                  onChange={() => {
                    dispatch(toggleAcceptAnyOdds())
                  }}
                  className={`${
                    acceptAnyOdds ? 'bg-[#F7941D]' : 'bg-white/50'
                  } relative inline-flex h-5 w-9 items-center rounded-full`}
                >
                  <span className="sr-only">Accept any odds changes</span>
                  <span
                    className={`${
                      acceptAnyOdds ? 'translate-x-4' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </Switch>
              </div>
              <button
                className={`flex w-full items-center justify-center space-x-2 text-white px-[1.375rem] transition duration-200 disabled:opacity-50 py-2 rounded-lg active-mouse-pointer`}
                disabled={!totalBetPrice || betPlaceLoad || placeLoading}
                onClick={() => placeBetHandle(user)}
              >
                <div className="h-5 flex justify-center items-center">
                  {betPlaceLoad || placeLoading ? (
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="inline w-5 h-5 text-white animate-spin fill-gray-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <SingleTicket className="w-5 h-5" />
                  )}
                </div>
                <span className="text-sm font-medium font-inter whitespace-nowrap">
                  <FormattedMessage
                    id="rightSidebar.footerPlaceBetAmount"
                    defaultMessage="Place bet"
                  />{' '}
                  {totalBetPrice
                    ? Number.isInteger(totalBetPrice)
                      ? totalBetPrice.toLocaleString('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 20,
                        })
                      : Number(totalBetPrice).toLocaleString('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 20,
                        })
                    : '0.00'}{' '}
                  USD
                </span>
              </button>
              <div className="font-inter text-xs flex flex-row items-center justify-end gap-3 flex-wrap">
                <p className="font-normal">
                  <FormattedMessage
                    id="rightSidebar.footerPossibleWin"
                    defaultMessage="Possible win"
                  />
                </p>
                <p className="font-semibold text-[#F7941D]">
                  ${Number(possibleWinPrice)?.toFixed(9)}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      <NotesModel
        modelData={notesModel}
        slips={listingData}
        multiBetNote={multibetNote}
        intl={intl}
      />
      <ChangeOddsModel
        isOpen={oddsModalVisible}
        onClickYes={betHandler}
        user={user}
        handleClose={closeOddsModal}
      />
    </>
  )
}

export default memo(RightSidebar)
